import React from "react";
import {Logo} from "./svgs";

import "../scss/styles.sass";

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: false
        };

        this.handleClick = this.handleClick.bind(this)
    }


    handleClick() {
        this.setState({
            clicked: !this.state.clicked
        })
    }

    render() {
        const {clicked} = this.state;

        return (
            <nav className="level is-mobile" id="custom-nav">
                <div className="level-item level-left">
                    <a href="/">
                        <Logo width="50" height="50" primaryColor="#38C361" secondaryColor="#38C361"/>
                    </a>
                </div>
                <div className="level-item level-right">
                    <a className="button is-primary is-outlined is-large is-rounded" href="/contact">Contact</a>
                </div>
            </nav>
        ) // end return
    } // End render
} // end Class