import React from 'react'

export default class ProjectComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          modalShow: false
        };

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        this.setState(state => ({
            modalShow: !state.modalShow
        }));
    }

    render() {
        let {image, name, description, website, customStyle} = this.props;
        return (
            <div className="column project-column is-12-mobile is-half-tablet is-one-third-desktop flex-center" onClick={this.handleClick}>
                <div className="box is-clipped">
                    <figure className="projectThumb">
                        <img src={image} alt={`${name} logo`} style={customStyle}/>
                    </figure>
                    <div className={`modal ${this.state.modalShow ? 'is-active': ''}`}>
                        <div className="modal-background" />
                        <div className="modal-card">
                            <header className="modal-card-head">
                                <h3>Project: {name}</h3>
                            </header>

                            <article className="modal-card-body">
                                <p>{description}</p>
                            </article>

                            <footer className="modal-card-foot">
                                <a href={website} className="button is-primary is-rounded is-outlined"
                                   target="_blank" rel="noopener noreferrer">Website</a>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}