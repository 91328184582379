import React from "react"

export const Logo = props => {
    return (
        <svg className={props.className} style={{width: props.width ? props.width : "inherit", height: props.height ? props.height : "inherit"}} viewBox="0 0 119 89" xmlns="http://www.w3.org/2000/svg">
            <rect width="117.16" height="88.2" fillOpacity="0" transform="translate(0.962006 0.799988)"/>
            <path d="M3.86001 89C3.02 89 2.30601 88.748 1.71801 88.244C1.21401 87.656 0.962006 86.942 0.962006 86.102V3.82399C0.962006 2.89999 1.21401 2.18599 1.71801 1.68199C2.30601 1.09399 3.02 0.799988 3.86001 0.799988H55.898C56.738 0.799988 57.41 1.09399 57.914 1.68199C58.502 2.18599 58.796 2.89999 58.796 3.82399V8.61199C58.796 9.45199 58.544 10.166 58.04 10.754C57.536 11.258 56.822 11.51 55.898 11.51H13.058V39.104H53.126C53.966 39.104 54.638 39.398 55.142 39.986C55.73 40.49 56.024 41.204 56.024 42.128V46.79C56.024 47.63 55.73 48.344 55.142 48.932C54.638 49.436 53.966 49.688 53.126 49.688H13.058V78.29H56.906C57.83 78.29 58.544 78.542 59.048 79.046C59.552 79.55 59.804 80.264 59.804 81.188V86.102C59.804 86.942 59.51 87.656 58.922 88.244C58.418 88.748 57.746 89 56.906 89H3.86001Z" fill={props.primaryColor}/>
            <path d="M58.2721 89C57.4321 89 56.7181 88.748 56.1301 88.244C55.6262 87.656 55.3741 86.942 55.3741 86.102V3.82399C55.3741 2.89999 55.6262 2.18599 56.1301 1.68199C56.7181 1.09399 57.4321 0.799988 58.2721 0.799988H64.5722C65.4962 0.799988 66.2101 1.09399 66.7141 1.68199C67.3022 2.18599 67.5962 2.89999 67.5962 3.82399V35.072L101.238 2.81599C102.582 1.47199 104.22 0.799988 106.152 0.799988H112.956C113.628 0.799988 114.216 1.05199 114.72 1.55599C115.224 2.05999 115.476 2.64799 115.476 3.31999C115.476 3.99199 115.308 4.53799 114.972 4.95799L76.0381 43.01L117.492 84.716C117.912 85.22 118.122 85.808 118.122 86.48C118.122 87.152 117.87 87.74 117.366 88.244C116.862 88.748 116.274 89 115.602 89H108.546C106.698 89 105.06 88.286 103.632 86.858L67.5962 51.452V86.102C67.5962 86.942 67.3022 87.656 66.7141 88.244C66.1261 88.748 65.4122 89 64.5722 89H58.2721Z" fill={props.secondaryColor}/>
        </svg>
    )
}

export const MaterialArrowDown = props => {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" fill={props.color ? props.color : "#81D742"} style={{width: props.width ? props.width : "inherit", height: props.height ? props.height : "inherit"}} viewBox="0 0 48 48">
            <path d="M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm0 24l-8-8h16l-8 8z"/>
        </svg>
    )
}