import React from 'react';
import './scss/styles.sass';
import {AboutSection, HeaderSection, ProjectSection, SkillSection} from "./Components/Sections";
import Header from "./Components/header";

const App = () => (
    <div className="index-container">
        <Header/>
        <HeaderSection/>
        <AboutSection/>
        <SkillSection/>
        <ProjectSection/>
    </div>
);

export default App;
