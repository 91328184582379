import React from 'react'
import ProjectComponent from "./project-component";

const projects = require('../projects');

// Section with Title + Subtitle
export const HeaderSection = () => {
    return (
        <section className="hero is-medium">
            <div className="hero-body is-medium">
                <header className="margin-bottom-large">
                    <div className="container has-text-centered">
                        <h1 className="title">Frontend Engineer, Designer & Code Lover</h1>
                        <h2 className="subtitle">Designing awesome code</h2>
                    </div>
                </header>
                <article className="flex-center">
                    <figure className="image is-200x200">
                        <img className="is-rounded" src="/images/profile-square.jpg" alt="self portrait" />
                    </figure>
                </article>
            </div>
        </section>
    )
};

// Brief Paragraph about me
export const AboutSection = () => {
    return (
        <section className="section is-medium is-primary has-text-centered is-long">
            <header>
                <h1 className="title">Evan Kemp</h1>
            </header>
            <article className="flex-center">
                <div className="columns is-centered">
                    <p className="column is-three-fifths subtitle">I have a year of experience writing front end development code during an internship with OhmConnect
                        (a consumer-facing service) and a year of experience coding websites for personal and educational uses.
                        Continually learning new languages motivates me, and I tend to pick up new skills very quickly,
                        so I welcome the challenge of adding to my skill set. I am not afraid of asking for help if there
                        is something I am not sure of; I know what my limits are and where I can improve. </p>
                </div>
            </article>
        </section>
    )
};

// skills
export const SkillSection = () => {
    return (
        <section className="section skills is-medium has-text-centered">
            <div className="container is-narrow">
                <div className="box">
                        <div className="columns is-centered">
                            {/* Design Column */}
                            <div className="column">
                                <figure className="image flex-center">
                                    <img src="/images/code-solid.svg" alt="Skill Icon Designer"/>
                                </figure>
                                <h1 className="title is-size-3">Designer</h1>
                                <p>I enjoy designing easy-to-use user interfaces that are minimalistic and puts the user
                                first</p>

                                {/* Design Categories */}
                                <h3 className="list-title is-size-6 has-text-primary">Things I enjoy Designing</h3>
                                <p>UX, UI, Mobile, Apps, logos</p>

                                {/* Design Tools */}
                                <h3 className="list-title is-size-6 has-text-primary">My Design Tools</h3>
                                <ul>
                                    <li>Adobe Creative Suite</li>
                                    <li>Figma Prototyping tool</li>
                                    <li>Pen & Paper</li>
                                    <li>Webflow</li>
                                </ul>
                            </div>

                            {/* Front-end Engineer Column */}
                            <div className="column">
                                <figure className="image flex-center">
                                    <img src="/images/code-solid.svg" alt="Skill Icon Designer"/>
                                </figure>
                                <h1 className="title is-size-3">Front-end Engineer</h1>
                                <p>I am deeply passionate about coding interfaces optimally, and cleanly</p>

                                {/* Design Categories */}
                                <h3 className="list-title is-size-6 has-text-primary">Languages</h3>
                                <p>HTML, CSS, Javascript, Vue.js, React, Python, MySQL</p>

                                {/* Design Tools */}
                                <h3 className="list-title is-size-6 has-text-primary">My Design Tools</h3>
                                <ul>
                                    <li>PyCharm</li>
                                    <li>Github/Bitbucket</li>
                                    <li>Chrome</li>
                                    <li>Webflow</li>
                                    <li>Terminal</li>
                                </ul>
                            </div>
                        </div>
                </div>
            </div>
        </section>
    )
};

// Projects
export const ProjectSection = () => {
    return (
        <section className="section is-medium has-text-centered">
            <header>
                <h1 className="title is-size-3">My projects</h1>
            </header>

            <article className="container is-narrow">
                <div className="columns is-mobile is-multiline">
                    {projects.map(project =>
                        <ProjectComponent key={project.name} name={project.name}
                                          description={project.description} website={project.website}
                                          image={project.image}
                                          customStyle={project.customStyle ? project.customStyle : null}/>)}
                </div>
            </article>
        </section>
    )
};